<template>
  <ProjectDetail projectType="reward"/>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import ProjectDetail from "@/pages/project/Detail.vue";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageRewardDetail";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {ProjectDetail},
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>